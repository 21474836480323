@charset "utf-8";
/*--------------------------------------------------------------------
  Utility
----------------------------------------------------------------------*/

/* テキスト
--------------------------------------------------*/
.u_em{ color: #ad0e28; }

/* 配置
--------------------------------------------------*/
.u_txtC{ text-align: center; }
.u_txtL{ text-align: left; }
.u_txtR{ text-align: right; }

/* webfont
--------------------------------------------------*/
.u_dosis{
  font-family: 'Dosis', sans-serif;
}


/* Responsive
--------------------------------------------------*/
.u_spItem{
  display: none;
}

@media #{$sp}{
  .u_pcItem{
    display: none;
  }
  .u_spItem{
    display: block;
  }
  .u_fixImg{
    width: 100%;
    height: auto;
  }
}
