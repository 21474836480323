@charset "utf-8";
@import "setting", "default", "global", "layout", "particular", "component",
  "utility", "module";

/*--------------------------------------------------------------------
  overwrite
----------------------------------------------------------------------*/
body {
  opacity: 0;
  padding-top: 0;
  transition: opacity 0.3s ease-out;
}

@media #{$sp} {
  .slider {
    .item_btn {
      a {
        padding: 7px 15px 6px;
        font-size: 0.9rem;
        &:after {
          width: 5px;
          height: 5px;
          margin-top: -3px;
          border-width: 2px 2px 0 0;
        }
      }
    }
  }
}

/*--------------------------------------------------------------------
  TOPページ
----------------------------------------------------------------------*/

/* slider
--------------------------------------------------*/
.slider {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: auto;
  margin: 0 auto;
  .items {
    position: relative;
    width: 100%;
    height: auto;
  }
  .info {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 11;
    width: 100%;
    padding: 0 80px;
    box-sizing: border-box;
    font-weight: bold;
    color: #fff;
    -webkit-transform: translateY(-30%);
    transform: translateY(-30%);
  }
  .thema,
  .txt {
    width: 100%;
    max-width: 1258px;
    margin: 0 auto;
    text-shadow: 1px 1px 1px #000;
  }
  .thema {
    margin-bottom: 1.1em;
    font-size: 2.1rem;
    line-height: 1.6;
    letter-spacing: 1.3px;
    h1 {
      font-weight: bold;
      strong {
        display: block;
        font-size: 4.7rem;
        font-weight: bold;
        line-height: 1.1;
      }
    }
  }
  .lead {
    margin-top: 0.7em;
    font-size: 2.1rem;
    letter-spacing: 1px;
  }
  .item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    img {
      width: 100%;
      height: auto;
    }
    &.is_active {
      z-index: 10;
    }
  }
  .item_btn {
    width: 260px;
    margin: 0 auto;
  }
  .pointer {
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 100%;
    text-align: center;
    letter-spacing: -0.4em;
    li {
      display: inline-block;
      position: relative;
      z-index: 10;
      width: 6px;
      height: 6px;
      border: 1px solid #ccc;
      border-radius: 10px;
      background: #fff;
      letter-spacing: normal;
      cursor: pointer;
      transition: background 0.3s cubic-bezier(0.19, 1, 0.22, 1);
      & + li {
        margin-left: 10px;
      }
    }
  }
  .pointer li:hover,
  .pointer .is_current {
    border-color: $pgColor01a;
    background: $pgColor01a;
  }
  .shift_btn {
    position: absolute;
    top: 50%;
    cursor: pointer;
    z-index: 12;
    width: 36px;
    height: 36px;
    margin-top: 25px;
    transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    span {
      display: block;
      overflow: hidden;
      position: relative;
      width: 36px;
      height: 36px;
      text-indent: 100%;
      white-space: nowrap;
      &::after {
        display: block;
        position: absolute;
        top: 50%;
        width: 9px;
        height: 9px;
        margin-top: -6px;
        border: 0;
        border-top: solid 3px #fff;
        border-right: solid 3px #fff;
        content: "";
      }
    }
  }
  .prev {
    left: -40px;
    background-position: 0 0;
    span::after {
      left: 14px;
      -webkit-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }
  }
  .next {
    right: -40px;
    background-position: 100% 0;
    span::after {
      left: 10px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  @media #{$pc} {
    .inner:hover .next {
      right: 30px;
    }
    .shift_btn span:hover {
      opacity: 0.5;
    }
    .inner:hover .prev {
      left: 30px;
    }
  }
}

/* lead_cont
--------------------------------------------------*/
.lead_cont {
  padding-bottom: 74px;
  background: #fff;
  .inner {
    width: 640px;
    margin: 0 auto;
  }
  .logo {
    padding: 60px 0 15px;
    text-align: center;
  }
  .ttl {
    margin-bottom: 27px;
    padding: 25px 0 21px;
    border: 1px solid $subColor;
    border-width: 1px 0;
    box-sizing: border-box;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.3;
    text-align: center;
    color: $subColor;
  }
  .txt {
    line-height: 1.7;
    letter-spacing: 0.05em;
    margin-bottom: 20px;
  }
  .btns {
    display: flex;
    max-width: 540px;
    margin: 50px auto 0;
    justify-content: space-between;
  }
  .btn {
    display: block;
    max-width: 240px;
    margin: 10px auto;
    &._sba {
      background: $pgColor01a;
    }
    &._ics {
      background: $pgColor04a;
    }
  }
}
.pc .lead_cont .logo img {
  width: 600px;
  height: auto;
}

/* article_cont
--------------------------------------------------*/
.programs_cont {
  padding-bottom: 80px;
  background: #f2eee8;
  .programs_ttl {
    display: flex;
    height: 180px;
    justify-content: center;
    align-items: center;
    &._sba {
      background: $pgColor01a;
    }
    &._ics {
      background: $pgColor04a;
    }
  }
  .inside {
    max-width: 800px;
    margin: 0 auto;
  }
  .programs_inner {
    margin: 35px auto;
    + .programs_inner {
      margin-top: 80px;
    }
  }
  .cate_ttl {
    margin-bottom: 20px;
    font-size: 3.2rem;
    font-weight: bold;
    font-family: "Noto Sans KR", sans-serif;
    line-height: 1;
    &::after,
    &::before {
      display: inline-block;
      width: 1px;
      height: 1em;
      margin-bottom: -4px;
      background: #333;
      content: "";
    }
    &::after {
      margin-left: 18px;
    }
    &::before {
      margin-right: 18px;
    }
  }
  .bottom_area {
    margin-top: 50px;
  }
  .btn_view {
    display: inline-block;
    font-size: 1.8rem;
    text-align: center;
    text-decoration: underline;
  }
  @media #{$pc} {
    .btn_view:hover {
      text-decoration: none;
    }
  }
}
.accordion_check {
  display: none;
  &:checked {
    & + .accordion_head {
      label::before {
        background-position: 0 32px;
      }
      & + .accordion_body {
        opacity: 1;
        height: auto;
        padding: 30px 0;
      }
    }
  }
}
.accordion_head {
  position: relative;
  width: 100%;
  margin: 10px 0;
}
.accordion_label {
  display: flex;
  width: 100%;
  min-height: 47px;
  border-left: 15px solid;
  padding-left: 20px;
  box-sizing: border-box;
  font-size: 2.2rem;
  color: #fff;
  align-items: center;
  cursor: pointer;
  &::before {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 100%;
    width: 32px;
    height: 32px;
    margin: -16px 0 0 -42px;
    background: url(/images/index/ac_btn.png);
    content: "";
  }
  &._c01 {
    border-color: $pgColor01b;
    background: $pgColor01a;
  }
  &._c02 {
    border-color: $pgColor02b;
    background: $pgColor02a;
  }
  &._c03 {
    border-color: $pgColor03b;
    background: $pgColor03a;
  }
  &._c04 {
    border-color: $pgColor04b;
    background: $pgColor04a;
  }
}
.accordion_body {
  overflow: hidden;
  height: 0;
  padding: 0;
  margin-top: -10px;
  background: #fff;
  box-shadow: 0 10px 5px -5px rgba(0, 0, 0, 0.1) inset;
  transition: all 0.4s ease;
}
.programs_item {
  padding: 0 50px;
  .ttl {
    margin-bottom: 20px;
    padding: 20px 0;
    border: solid #aaa;
    border-width: 1px 0;
    font-size: 2rem;
    text-align: center;
  }
  .col {
    display: flex;
    margin-bottom: 30px;
    padding: 0 30px;
    justify-content: space-between;
  }
  .img {
    padding: 0 3px;
    text-align: center;
    img {
      max-width: 260px;
      height: auto;
    }
  }
  .outline {
    margin-left: 35px;
    padding: 0 10px;
  }
  .data {
    display: flex;
    margin-top: 20px;
    border-top: 1px solid;
    flex-wrap: wrap;
    justify-content: center;
    dt,
    dd {
      padding: 6px 0 5px;
      border-bottom: 1px solid;
      line-height: 1.3;
    }
    dt {
      display: flex;
      width: 70px;
      padding-right: 10px;
      align-items: center;
      justify-content: space-between;
    }
    dd {
      width: calc(100% - 80px);
      span {
        display: block;
        padding: 5px 0 4px;
        border-radius: 3px;
        text-align: center;
        color: #fff;
        letter-spacing: -0.05em;
      }
    }
    &._c01 {
      border-color: $pgColor01a;
      dt,
      dd {
        border-color: $pgColor01a;
      }
      dd {
        span {
          background: $pgColor01a;
        }
      }
    }
    &._c02 {
      border-color: $pgColor02a;
      dt,
      dd {
        border-color: $pgColor02a;
      }
      dd {
        span {
          background: $pgColor02a;
        }
      }
    }
    &._c03 {
      border-color: $pgColor03a;
      dt,
      dd {
        border-color: $pgColor03a;
      }
      dd {
        span {
          background: $pgColor03a;
        }
      }
    }
    &._c04 {
      border-color: $pgColor04a;
      dt,
      dd {
        border-color: $pgColor04a;
      }
      dd {
        span {
          background: $pgColor04a;
        }
      }
    }
  }
  .btn {
    display: flex;
    width: 220px;
    height: 40px;
    margin: 0 auto;
    border-radius: 20px;
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    letter-spacing: normal;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-out;
    &::before {
      display: block;
      margin-right: 10px;
      @extend %arwDefault;
      @extend %arwSizeL;
      @extend %arwBorderM;
      @extend %arwColorW;
    }
    &._c01 {
      background: $pgColor01b;
    }
    &._c02 {
      background: $pgColor02b;
    }
    &._c03 {
      background: $pgColor03b;
    }
    &._c04 {
      background: $pgColor04b;
    }
  }
  @media #{$pc} {
    .btn:hover {
      opacity: 0.8;
    }
  }
}

/* 全体図
--------------------------------------------------*/
.link_holder {
  max-width: 780px;
  margin: 0 auto 30px;
  padding: 0 10px;
}
.numbers_list {
  counter-reset: counter;
  list-style: none;
  padding: 0;
  li {
    position: relative;
    margin-bottom: 10px;
    font-size: 1.6rem;
    word-break: break-all;
    &::before {
      display: block;
      float: left;
      width: 0.9em;
      height: 1em;
      margin: 0 10px 0 1px;
      border-radius: 50%;
      text-align: center;
      color: #222;
      letter-spacing: -0.2em;
      content: counter(counter);
      counter-increment: counter;
    }
    &::after {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 1.1em;
      height: 1.1em;
      border: 1px solid #333;
      border-radius: 50%;
      content: "";
    }
  }
}

/* article_cont
--------------------------------------------------*/
.article_cont {
  padding-bottom: 80px;
  background: #f2eee8;
}
.article_cont .ttl {
  text-align: center;
}
.pc .article_cont .ttl img {
  width: 249px;
  height: auto;
}

/* article_cards */
.article_cont .article_cards {
  overflow: hidden;
  margin-left: -12px;
  letter-spacing: -0.4em;
}
.article_cont .card {
  display: inline-block;
  width: 241px;
  margin-left: 12px;
  box-sizing: border-box;
  background: #fff;
  vertical-align: top;
  letter-spacing: normal;
}
.article_cont .card:nth-of-type(n + 5) {
  margin-top: 12px;
}
.article_cont a {
  display: block;
  overflow: hidden;
  padding: 8px;
}
.pc .article_cont a:hover .txt {
  text-decoration: underline;
}
.article_cont .img {
  overflow: hidden;
  height: 140px;
  margin-bottom: 23px;
}
.article_cont .img img {
  width: 100%;
  height: auto;
}
.article_cont .date,
.article_cont .label {
  box-sizing: border-box;
}
.article_cont .date {
  float: left;
  padding-left: 5px;
  color: $pgColor01a;
  font-size: 1.2rem;
  line-height: 1.9;
}
.article_cont .label {
  float: right;
  width: 10em;
  margin-right: -4px;
  padding-right: 5px;
  span {
    display: block;
    float: right;
    min-width: 72px;
    padding: 2px 3px 1px;
    margin: 4px 4px 0 0;
    border-radius: 2px;
    box-sizing: border-box;
    background: $pgColor01a;
    color: #fff;
    font-size: 1.1rem;
    line-height: 1;
    text-align: center;
  }
}
.article_cont .txt {
  clear: both;
  padding: 10px 5px 14px;
  font-size: 1.3rem;
  line-height: 1.5;
}
.article_cont .is_Omit {
  position: relative;
}
.article_cont .is_Omit:after {
  content: "…";
}

/* btn */
.article_cont .all_btn {
  margin-top: 50px;
  text-align: center;
  letter-spacing: -0.4em;
}
.article_cont .all_btn a {
  display: inline-block;
  width: 280px;
  padding: 15px 0 12px;
  border-radius: 20px;
  background: #fff;
  color: #333;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  letter-spacing: normal;
  transition: all 0.1s ease-out;
}
.pc .article_cont .all_btn a:hover {
  color: #999;
}
.article_cont .all_btn span {
  position: relative;
  padding-left: 15px;
}
.article_cont .all_btn span:before {
  @extend %arwDefault;
  display: block;
  position: absolute;
  top: 50%;
  left: -5px;
  @extend %arwSizeL;
  @extend %arwBorderS;
  margin-top: -7px;
  @extend %arwColorB;
}
.pc .article_cont .all_btn a:hover span:before {
  border-color: #999;
}

/* モーダル
--------------------------------------------------*/
.modal_panel {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1003;
  height: 563px;
  margin-top: -280px;
  .modal_inner {
    overflow: auto;
    position: relative;
    height: 100%;
    background: #fff;
    box-sizing: border-box;
  }
  .btn_close {
    display: inline-block;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 1002;
    width: 42px;
    height: 42px;
    margin: 15px 0 0 -57px;
    border: 1px solid #000;
    text-indent: 100%;
    white-space: nowrap;
    &::after,
    &::before {
      position: absolute;
      display: block;
      left: 50%;
      top: 50%;
      width: 20px;
      height: 2px;
      margin-left: -10px;
      background: #000;
      content: "";
    }
    &::after {
      -webkit-transform: rotate(45deg) translateY(0px);
      transform: rotate(45deg) translateY(0px);
    }
    &::before {
      -webkit-transform: rotate(-45deg) translateY(0px);
      transform: rotate(-45deg) translateY(0px);
    }
  }
}
.modal_overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1002;
  width: 100%;
  height: 100%;
}
.modal_layer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

/* レスポンシブ
--------------------------------------------------*/
@media screen and (min-width: 1250px) {
  .slider .thema {
    font-size: 1.9vw;
  }
  .slider .thema strong {
    font-size: 3.8vw;
  }
  .slider .lead {
    font-size: 1.73vw;
  }
}

@media screen and (min-width: 1800px) {
  .slider .thema {
    font-size: 3.5rem;
  }
  .slider .thema strong {
    font-size: 7.3rem;
  }
  .slider .lead {
    font-size: 3.2rem;
  }
}

@media #{$sp} {
  /* slider
  --------------------------------------------------*/
  .slider {
    overflow: visible;
  }
  .slider .info {
    padding: 0;
  }
  .slider .thema,
  .slider .lead {
    font-size: 1.1rem;
    text-align: center;
  }
  .slider .thema strong {
    font-size: 2.5rem;
    line-height: 1.2;
  }
  .slider .lead {
    margin-top: 1.5em;
  }
  .slider .item_btn {
    width: 200px;
  }
  .slider .shift_btn {
    margin-top: 12px;
  }
  .slider .prev {
    left: 0;
  }
  .slider .next {
    right: 0;
  }

  /* lead_cont
  --------------------------------------------------*/
  .lead_cont {
    padding-bottom: 57px;
    margin: 15px 0;
    .inner {
      width: auto;
    }
    .logo {
      padding: 20px 0 0;
    }
    .logo img {
      width: 300px;
    }
    .ttl {
      margin-bottom: 30px;
      padding: 21px 0 18px;
      font-size: 1.2rem;
      line-height: 1.4;
    }
    .txt {
      font-size: 1.2rem;
      line-height: 1.5;
    }
    .btns {
      flex-direction: column;
    }
  }

  /* programs_cont
  --------------------------------------------------*/
  .programs_cont {
    padding-bottom: 50px;
  }
  .programs_item {
    padding: 0 10px;
    .ttl {
      font-size: 1.6rem;
    }
    .col {
      padding: 0 5px;
      flex-direction: column;
    }
    .outline {
      margin: 20px 0 0;
      padding: 0;
    }
  }

  .accordion_label {
    padding: 5px 45px 4px 6px;
    border-left-width: 8px;
    font-size: 1.6rem;
    line-height: 1.3;
  }

  /* 全体図
  --------------------------------------------------*/
  .link_holder {
    margin-top: 30px;
    padding: 0 20px;
  }
  .numbers_list {
    li {
      margin-bottom: 20px;
      padding-left: 30px;
      font-size: 1.4rem;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  /* article_cont
  --------------------------------------------------*/
  .article_cont {
    padding-bottom: 50px;
  }

  /* article_cards */
  .article_cont .article_cards {
    margin: -20px 0 0;
  }
  .article_cont .card {
    display: block;
    width: 100%;
    margin: 20px 0 0;
  }
  .article_cont .card:nth-of-type(n + 5) {
    margin: 20px 0 0;
  }
  .article_cont .img {
    width: 100%;
    height: auto;
  }
  .article_cont .date {
    width: 7em;
  }
  .article_cont .label {
    width: 14.5em;
  }
  .article_cont .txt {
    padding-top: 15px;
  }

  /* btn */
  .article_cont .all_btn a {
    width: 260px;
    padding: 15px 0 12px;
    font-size: 1.3rem;
  }

  /* modal
  --------------------------------------------------*/
  .modal_panel {
    width: calc(100% - 10px);
    height: 50%;
    .modal_inner {
      padding: 60px 0 0;
    }
  }
}

/* faq
  --------------------------------------------------*/
#Content.l_main {
  min-height: unset;
}
