@charset "utf-8";
/*--------------------------------------------------------------------
  Module : ひとかたまりの部品群
----------------------------------------------------------------------*/

/* _pagination
--------------------------------------------------*/
.m_pagination {
  position: relative;
  text-align: center;
  letter-spacing: -0.4em;
  li {
    display: inline-block;
    margin: 0 5px;
    vertical-align: middle;
    letter-spacing: normal;
  }
  a {
    position: relative;
    text-decoration: underline;
  }
  .is_active {
    text-decoration: none;
  }
  .prev,
  .next {
    position: absolute;
    margin: 0;
  }
  .prev {
    left: 0;
    span {
      padding-left: 15px;
    }
  }
  .next {
    right: 0;
    span {
      padding-right: 15px;
    }
  }
  .prev a:before,
  .next a:after {
    @extend %arwDefault;
    @extend %arwSizeL;
    @extend %arwBorderS;
    color: $pgColor01a;
  }
  .prev a:before {
    transform: rotate(-135deg);
  }
}
.pc .m_pagination a:hover {
  text-decoration: none;
}

/* _toggelUnit
--------------------------------------------------*/
.m_toggelUnits {
  counter-reset: num;
  .unit_head,
  .unit_body {
    position: relative;
  }
  .unit_head {
    background: #777;
    cursor: pointer;
    span {
      display: block;
      background: #aaa;
    }
    &::before,
    &::after,
    span::after,
    span::before,
    .unit_body::before {
      display: block;
      position: absolute;
    }
    &::before,
    &::after,
    span::before,
    span::after {
      top: 50%;
      text-align: center;
      color: #fff;
    }
    &::before {
      margin-top: -0.6em;
      counter-increment: num;
      font-weight: bold;
    }
    &:not(._question)::before {
      content: counter(num);
    }
    &._question::before {
      content: "Q" counter(num);
    }
    &::after {
      top: 50%;
      width: 24px;
      height: 24px;
      margin-top: -14px;
      border: 2px solid #fff;
      border-radius: 50%;
      content: "";
    }
    span::before,
    span::after {
      background: #fff;
      content: "";
    }
    span::before {
      right: 33px;
      margin-top: -6px;
      width: 2px;
      height: 12px;
    }
    span::after {
      right: 28px;
      margin-top: -1px;
      width: 12px;
      height: 2px;
    }
    &.is_active {
      span {
        background: #ccc;
      }
      span:before {
        display: none;
      }
    }
  }
  .unit_body {
    background: #f2f2f2;
    &._answer::before {
      position: absolute;
      left: 0;
      width: 1em;
      height: 1em;
      font-weight: bold;
      content: "A";
      color: #777;
    }
  }
  @media #{$sp} {
    .unit {
      margin-top: 30px;
    }
    .unit_head {
      font-size: 1.3rem;
      &::before {
        width: 50px;
        font-size: 1.8rem;
      }
      &::after {
        right: 15px;
      }
      span {
        margin-left: 50px;
        padding: 15px 50px 12px 15px;
      }
      span::before {
        right: 28px;
      }
      span::after {
        right: 23px;
      }
    }
    .unit_body {
      .inner {
        display: inline-block;
        padding: 20px 18px 20px 50px;
      }
      &._answer::before {
        top: 16px;
        margin-left: 18px;
        font-size: 2rem;
      }
    }
  }
  @media #{$pc} {
    .unit {
      margin-top: 40px;
    }
    .unit_head {
      font-size: 1.6rem;
      span {
        margin-left: 60px;
        padding: 20px 70px 17px 15px;
      }
      &:hover span {
        background: #ccc;
      }
      &.is_active {
        &:hover span {
          background: #aaa;
        }
      }
      &::before {
        width: 60px;
        font-size: 2.1rem;
      }
      &::after {
        right: 15px;
      }
      span::before {
        right: 28px;
      }
      span::after {
        right: 23px;
      }
    }
    .unit_body {
      &._answer::before {
        top: 22px;
        margin-left: 24px;
        font-size: 2.4rem;
      }
      .inner {
        display: inline-block;
        padding: 28px 40px 28px 60px;
      }
    }
  }
}
