@charset "utf-8";
/*--------------------------------------------------------------------
  layout : 配置・枠
----------------------------------------------------------------------*/
.l_base{
  width: 1000px;
  margin: 0 auto;
}
.l_main{
  .pc &{
    min-height: calc(100vh - 389px);
  }
  margin: 60px 0 100px;
}
.l_block{
  margin: 40px 0;
}
.l_colArticle{
  float: left;
  width: 720px;
}
.l_grid{
  overflow: hidden;
  margin-left: -20px;
}
.l_2col{
  float: left;
  width: 350px;
  margin-left: 20px;
}
.l_colAside{
  float: right;
  width: 240px;
}

@media #{$sp}{
  .l_base{
    width: auto;
    padding: 0 20px;
  }
  .l_main{
    margin: 50px 0;
  }
  .l_block{
    margin: 25px 0;
  }
  .l_colArticle{
    float: none;
    width: 100%;
  }
  .l_grid{
    margin: -20px 0 0;
  }
  .l_2col{
    float: none;
    width: 100%;
    margin: 20px 0 0;
  }
  .l_colAside{
    float: none;
    width: 100%;
  }
}

@media #{$spMin}{
  .l_base{
    padding: 0 10px;
  }
}
