@charset "utf-8";
/*--------------------------------------------------------------------
  Particular : ページに一つしか存在しない固有のモジュール
----------------------------------------------------------------------*/

/* _breadcrumbs
--------------------------------------------------*/
.p_breadcrumbs {
  overflow: hidden;
  margin: 10px auto 0;
}
.p_breadcrumbs li {
  position: relative;
  float: left;
  font-size: 1.1rem;
}
.p_breadcrumbs li + li {
  margin-left: 30px;
}
.p_breadcrumbs li:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 1em;
  height: 1em;
  margin: -3px 0 0 -21px;
  font-size: 1.5rem;
  content: " > ";
}
.p_breadcrumbs li a {
  text-decoration: underline;
}
.pc .p_breadcrumbs li a:hover {
  text-decoration: none;
}

@media #{$sp} {
  .p_breadcrumbs li + li {
    margin-left: 22px;
  }
  .p_breadcrumbs li:before {
    margin: 0 0 0 -14px;
    font-size: 1.2rem;
  }
}

/* _sideMenu
--------------------------------------------------*/
.p_sideMenu {
  border: 5px solid #aaa;
  background: #aaa;
}
.p_sideMenu .side_ttl {
  height: 55px;
  padding: 17px 0 0 15px;
  color: #fffdf8;
  font-size: 1.5rem;
  box-sizing: border-box;
}
.p_sideMenu .side_ttl a {
  color: #fffdf8;
}
.pc .p_sideMenu .side_ttl a:hover {
  text-decoration: underline;
}
.p_sideMenu .side_list > li {
  display: table;
  position: relative;
  width: 100%;
}
.p_sideMenu .side_list li + li {
  margin-top: 1px;
}
.p_sideMenu .side_list a {
  display: table-cell;
  width: 100%;
  height: 50px;
  padding: 0 20px 0 10px;
  box-sizing: border-box;
  background: #fff;
  vertical-align: middle;
  line-height: 1.2;
  transition: all 0.1s ease-out;
}
.pc .p_sideMenu .side_list a:hover,
.p_sideMenu .side_list .is_active {
  background: $pgColor01a;
  color: #fff;
}
.p_sideMenu .side_list a:after {
  @extend %arwDefault;
  position: absolute;
  top: 50%;
  right: 14px;
  @extend %arwSizeL;
  margin-top: -4px;
  @extend %arwBorderS;
  color: $pgColor01a;
}
.pc .p_sideMenu .side_list a:hover:after {
  background-position: -60px 50%;
}
.p_sideMenu .side_list .is_active:after {
  display: none;
}
.p_sideMenu .blank {
  position: relative;
}
.p_sideMenu .blank:before,
.p_sideMenu .blank:after {
  display: inline-block;
  position: absolute;
  top: 50%;
  width: 6px;
  height: 6px;
  border: 1px solid #333;
  content: "";
}
.p_sideMenu .blank:after {
  right: -15px;
  margin-top: -5px;
}
.p_sideMenu .blank:before {
  right: -12px;
  margin-top: -2px;
}
.p_sideMenu a:hover .blank:before,
.p_sideMenu a:hover .blank:after {
  border-color: #fff;
}

@media #{$sp} {
  .p_sideMenu {
    border-width: 0 2px 2px;
  }
  .p_sideMenu .side_ttl {
    height: 50px;
    padding-left: 10px;
  }
}

/* _pageTop
--------------------------------------------------*/
.p_pageTop {
  display: none;
  position: absolute;
  top: -70px;
  right: 0;
}
.p_pageTop.is_fix {
  position: fixed;
}
.p_pageTop a {
  position: relative;
  display: block;
  width: 70px;
  height: 70px;
  padding-top: 39px;
  background: url(/images/btn_pagetop.png) no-repeat 0 0 $pgColor01a;
  color: #fff;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;
  transition: all 0.1s ease-out;
}
.pc .p_pageTop a:hover {
  background-color: #c34;
}

@media #{$sp} {
  .p_pageTop {
    display: none;
  }
}
.business-page-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 10;
}
@media #{$sp} {
  .business-page-top {
    width: 42px;
  }
}
