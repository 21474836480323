@charset "utf-8";
/*--------------------------------------------------------------------
  global : 全ページ共通
----------------------------------------------------------------------*/

/* _header
--------------------------------------------------*/
.g_header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  .inner {
    position: relative;
    width: 1000px;
    padding: 0 10px;
    margin: 0 auto;
    box-sizing: border-box;
    .pc & {
      background: url(/images/header_bg_pc.png) no-repeat 80% 31%;
    }
  }
  &.eng {
    .inner {
      background: none;
    }
  }
}

/* logo */
.g_header .logo {
  float: left;
}
.pc .g_header .logo img {
  width: 360px;
  height: auto;
}

/* gnav */
.g_header {
  &.eng {
    .pc & {
      .gnav {
        position: absolute;
        bottom: 20px;
        left: 50%;
        width: 1000px;
        margin-left: -500px;
        text-align: right;
      }
      .help_nav {
        letter-spacing: -0.4em;
        .thema {
          display: inline-block;
          border-right: 1px solid #000;
          letter-spacing: normal;
          span {
            position: relative;
          }
          span:after {
            display: block;
            position: absolute;
            bottom: -10px;
            left: 0;
            opacity: 0;
            width: 100%;
            height: 2px;
            margin: 0 auto;
            background: #000;
            content: "";
            transition: opacity 0.1s ease-out;
          }
        }
        .thema:first-child {
          border-left: 1px solid #000;
        }
        a {
          display: block;
          width: 100%;
          padding: 1px 25px;
          box-sizing: border-box;
          text-align: center;
          font-weight: bold;
          transition: all 0.1s ease-out;
          &:hover:not(.is_active) {
            color: $pgColor01a;
          }
          &.is_active {
            span:after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

/* lang */
.g_header .lang {
  padding-top: 16px;
  text-align: right;
  letter-spacing: normal;
}
.g_header .lang a {
  display: inline-block;
  color: #ccc;
  font-size: 1.2rem;
}
.g_header .lang .lang_link_first:after {
  display: inline-block;
  margin: 0 1px 0 4px;
  content: "/";
  color: #ccc;
}
.g_header .lang .is_active span,
.pc .g_header .lang a:hover span {
  text-decoration: underline;
  color: #000;
}
/* pg_menu */
.g_header .pg_menu {
  display: flex;
  justify-content: flex-end;
  padding: 5px 0 0 0;
}
.g_header .pg_menu > li a {
  display: block;
}
.g_header .pg_menu_list {
  position: relative;
  margin: 0 0 0 2px;
  .pg_menu_list_link::after {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    margin: 0 0 0 9px;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .pg_menu_list_inner {
    visibility: hidden;
    opacity: 0;
    z-index: 1;
    position: absolute;
    top: 29px;
    width: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    text-align: left;
    li {
      a {
        padding: 6px 12px;
      }
      a:link,
      a:visited {
        background-color: #901;
        color: #fff;
      }
      a:hover,
      a:active {
        background-color: #c34;
        color: #fff;
      }
    }
  }
}
.g_header .pg_menu_list_link {
  padding: 6px 36px;
}
.g_header .pg_menu_list_link:link,
.g_header .pg_menu_list_link:visited {
  background-color: #901;
  color: #fff;
}
.g_header .pg_menu_list_link:hover,
.g_header .pg_menu_list_link:active {
  background-color: #c34;
  color: #fff;
}
.g_header .pg_menu_list:hover .pg_menu_list_inner {
  visibility: visible;
  opacity: 1;
}

@media #{$sp} {
  .g_header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    padding-bottom: 0;
  }
  .g_header.is_active {
    height: 100%;
  }

  /* logo */
  .g_header .logo {
    margin-left: 15px;
    img {
      width: 205px;
      height: auto;
    }
  }

  /* lang */
  .g_header .lang {
    display: none;
  }

  /* gnav */
  .g_header .gnav {
    display: none;
    position: absolute;
    top: 60px;
    z-index: 100;
    width: 0;
    height: 100%;
    margin-top: 0;
    text-align: left;
  }
  .g_header .gnav .inside {
    position: relative;
    height: 100%;
  }
  .g_header .sp_scroll {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    padding: 40px 0 60px;
    box-sizing: border-box;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  /* nav */
  .g_header .help_nav li {
    display: block;
    float: left;
    width: 140px;
    width: -webkit-calc((100% - 20px) / 2);
    width: calc((100% - 20px) / 2);
    height: 2em;
    font-weight: bold;
  }
  .g_header .conts_nav a,
  .g_header .help_nav a {
    position: relative;
  }
  .g_header .conts_nav a:before,
  .g_header .help_nav a:before {
    position: absolute;
    top: 50%;
    width: 7px;
    height: 7px;
    margin-top: -5px;
    border-top: solid 2px;
    border-right: solid 2px;
    content: "";
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  /* conts_nav */
  .g_header .conts_nav {
    padding: 0 20px 41px;
  }
  .g_header .conts_nav a {
    display: block;
    padding: 19px 0 18px;
    font-size: 1.2rem;
  }
  .g_header .conts_nav li {
    border: 1px solid #ccc;
    border-width: 0 0 1px;
  }
  .g_header .conts_nav li:first-child {
    border-top-width: 1px;
  }
  .g_header .conts_nav a:before {
    right: 10px;
    border-color: #000;
  }

  /* blank */
  .g_header .blank {
    position: relative;
  }
  .g_header .blank:before,
  .g_header .blank:after {
    display: inline-block;
    position: absolute;
    top: 100%;
    width: 4px;
    height: 4px;
    border: 1px solid #333;
    content: "";
  }
  .g_header .blank:before {
    right: -9px;
    margin-top: -12px;
  }
  .g_header .blank:after {
    right: -11px;
    margin-top: -14px;
  }

  /* help_nav */
  .g_header .help_nav {
    padding: 24px 20px;
    background: #333;
  }
  .g_header .help_nav a {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 18px;
    color: #999;
    font-size: 1.2rem;
  }
  .g_header .help_nav a:before {
    left: -3px;
    border-color: #999;
  }
  .g_header .help_nav .blank:before,
  .g_header .help_nav .blank:after {
    border-color: #999;
  }

  /* sp_menu */
  .g_header .sp_menu {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    width: 60px;
    height: 60px;
    background: #901;
  }
  .g_header .btn_menu {
    display: block;
    position: relative;
    overflow: hidden;
    width: 60px;
    height: 60px;
    text-indent: 100%;
    white-space: nowrap;
    cursor: pointer;
  }
  .g_header .btn_menu:after,
  .g_header .btn_menu span:after,
  .g_header .btn_menu span:before {
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    margin-left: -11px;
    width: 23px;
    height: 2px;
    background: #fff;
    content: "";
    transition: all 0.2s;
  }
  .g_header .btn_menu:after {
    margin-top: -1px;
  }
  .g_header .btn_menu span:before {
    margin-top: -7px;
  }
  .g_header .btn_menu span:after {
    margin-top: 5px;
  }
  .g_header.is_active .btn_menu:after {
    margin: 0 0 0 50%;
    opacity: 0;
  }
  .g_header.is_active .btn_menu span:before {
    margin-top: 1px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .g_header.is_active .btn_menu span:after {
    margin-top: 1px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@media #{$spMin} {
  .g_header .logo {
    margin-left: 10px;
  }
  .g_header .inner {
    background-size: auto 16px;
  }
  .g_header .conts_nav a {
    padding: 19px 25px 18px 0;
  }
  .g_header .help_nav li:nth-child(even) {
    margin-left: 15px;
  }
  .g_header .help_nav a {
    letter-spacing: -1px;
  }
  .g_header .sp_menu,
  .g_header .btn_menu {
    width: 50px;
  }
}

/* _footer
--------------------------------------------------*/
.g_footer {
  position: relative;
  z-index: 900;
  width: 100%;
  background: #333;
}
.g_footer .inner,
.g_footer .foot_area {
  width: 1000px;
  margin: 0 auto;
}

/* foot_area */
.g_footer .foot_area {
  display: table;
  padding: 70px 0 80px;
}
.g_footer .col {
  display: table-cell;
  position: relative;
  vertical-align: top;
}
.g_footer .foot_area .logo {
  margin-top: 10px;
  font-size: 1.6rem;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3",
    "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: bold;
  color: #888;
}
.g_footer .foot_area img {
  display: block;
  width: 397px;
  height: auto;
  margin-bottom: 15px;
}
.g_footer .bnr {
  img {
    width: 100%;
    height: auto;
  }
  @media #{$pc} {
    display: flex;
    width: 240px;
    margin: 0 0 20px auto;
    a {
      transition: 0.3s ease;
      &:hover {
        opacity: 0.5;
      }
    }
  }
}

/* address */
.g_footer .address {
  display: inline-block;
  margin-top: 10px;
  vertical-align: top;
  font-size: 1.2rem;
}
.g_footer .address:first-child {
  margin-right: 20px;
}
.g_footer .address dt {
  margin-bottom: 3px;
  color: #fff;
  line-height: 1;
}
.g_footer .address dd {
  color: #999;
  line-height: 1.5;
}

/* help_nav */
.g_footer .help_nav {
  margin-top: 35px;
  font-size: 1.1rem;
  letter-spacing: -0.4em;
}
.g_footer .help_nav li {
  display: inline-block;
  letter-spacing: normal;
  margin-right: 26px;
}
.g_footer .help_nav li:last-of-type {
  margin-right: 0;
}
.g_footer .help_nav a {
  display: block;
  position: relative;
  padding-left: 14px;
  border-color: #fff;
  border-width: 2px 2px 0 0;
}
.g_footer .conts_nav a:after,
.g_footer .help_nav a:before {
  display: block;
  position: absolute;
  @extend %arwSizeM;
  border-top: solid 1px;
  border-right: solid 1px;
  content: "";
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.g_footer .conts_nav a:after {
  top: 50%;
}
.pc .g_footer .help_nav a:hover {
  text-decoration: underline;
}
.g_footer .help_nav a:before {
  left: -3px;
  margin-top: 4px;
  border-color: #999;
}
.pc .g_footer .lang {
  display: none;
}

/* blank */
.g_footer .blank {
  position: relative;
}
.g_footer .blank:before,
.g_footer .blank:after {
  display: inline-block;
  position: absolute;
  top: 100%;
  width: 4px;
  height: 4px;
  border: 1px solid;
  content: "";
}
.g_footer .conts_nav .blank:before,
.g_footer .conts_nav .blank:after {
  color: #fff;
}
.g_footer .help_nav .blank:before,
.g_footer .help_nav .blank:after {
  color: #999;
}
.g_footer .blank:before {
  right: -9px;
  margin-top: -12px;
}
.g_footer .blank:after {
  right: -11px;
  margin-top: -14px;
}

/* copy */
.g_footer .copy {
  position: absolute;
  bottom: 0;
  color: #666;
  font-size: 1.2rem;
}

@media #{$sp} {
  .g_footer .foot_row:first-child {
    height: auto;
    padding-top: 0;
  }
  .g_footer .inner,
  .g_footer .foot_area {
    width: 100%;
  }

  /* nav */
  .g_footer .conts_nav a {
    font-size: 1.4rem;
    font-weight: bold;
  }
  .g_footer .conts_nav a:after {
    width: 7px;
    height: 7px;
    margin-top: -5px;
    border-top-width: 2px;
    border-right-width: 2px;
  }

  /* conts_nav */
  .g_footer .conts_nav li + li {
    border-top: 1px solid #999;
  }
  .g_footer .conts_nav a {
    display: block;
    position: relative;
    width: 100%;
    padding: 20px;
    background: #aaa;
    box-sizing: border-box;
    color: #fff;
    letter-spacing: -0.5px;
  }
  .g_footer .conts_nav a:after {
    right: 23px;
    border-color: #fff;
  }

  /* foot_area */
  .g_footer .foot_area {
    display: block;
    padding: 50px 0 120px;
  }
  .g_footer .foot_area .col {
    display: block;
    position: static;
  }
  .g_footer .foot_area .col:first-child {
    width: 100%;
    margin-bottom: 40px;
    padding-right: 0;
  }
  .g_footer .foot_area {
    text-align: center;
    img {
      width: 260px;
      margin: 0 auto 15px;
    }
  }

  /* address */
  .g_footer .address,
  .g_footer .address:first-child {
    display: block;
    width: 255px;
    margin: 25px auto;
  }

  /* help_nav */
  .g_footer .help_nav {
    margin-top: 47px;
    padding: 18px 0 27px;
    border: 1px solid #444;
    border-width: 1px 0;
    text-align: left;
  }
  .g_footer .help_nav li {
    box-sizing: border-box;
    width: 140px;
    width: -webkit-calc((100% - 50px) / 2);
    width: calc((100% - 50px) / 2);
    margin: 10px 0 0 0;
    padding: 0 16px;
    vertical-align: middle;
  }
  .g_footer .help_nav li + li {
    margin-left: 0;
  }

  /* copy */
  .g_footer .copy {
    width: 100%;
    margin-bottom: 3.4em;
    text-align: center;
    line-height: 1.2;
  }
}

@media #{$spMin} {
  .g_footer .conts_nav a {
    padding: 20px 35px 20px 15px;
  }
  .g_footer .conts_nav a:after {
    right: 16px;
  }
}

/* help_nav_new */
.g_footer .help_nav {
  margin-top: 45px;
}

.g_footer .help_nav li {
  margin-top: 10px;
  font-size: 1.1rem;
}

.g_footer .help_nav ul:first-child li + li {
  margin-left: 42px;
}

.g_footer .help_nav li + li {
  margin-left: 30px;
}

.g_footer .help_nav._eng .pc,
ul:first-child li + li {
  margin-left: 0;
}

.g_footer .help_nav a,
.g_footer .help_nav a:before {
  color: #fff;
}

.g_footer .help_nav a:before {
  @extend %arwSizeS;
}

@media #{$sp} {
  .g_footer .help_nav {
    width: 320px;
    margin: 47px auto 0;
    padding: 18px 20px 27px;
    border: 1px solid #444;
    border-width: 1px 0;
    box-sizing: border-box;
  }
  .g_footer .help_nav li {
    width: 100%;
  }

  .g_footer .help_nav li:nth-child(even) {
    margin-left: 20px;
  }

  .g_footer .help_nav li + li {
    margin-left: 0;
  }

  .g_footer .help_nav ul:first-child li {
    display: block;
  }

  .g_footer .help_nav ul:first-child li + li {
    margin: 10px 0 0 0;
  }

  .g_footer .help_nav ul + ul li {
    width: 140px;
    width: calc((100% - 20px) / 2);
    margin-top: 10px;
  }

  .g_footer .help_nav._eng {
    padding: 18px 14px 27px;
  }
}

@media #{$spMin} {
  .g_footer .help_nav:not(._eng) {
    padding: 18px 25px 27px;
  }
}
