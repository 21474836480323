/* Media Queries */
$pc: "screen and (min-width: 835px)";
$sp: "screen and (max-width: 834px)";
$spMin: "screen and (max-width: 320px)";

/* 色 */
$subColor: #b96;
$pgColor01a: #901;
$pgColor01b: #700;
$pgColor01c: #a12;
$pgColor02a: #612;
$pgColor02b: #501;
$pgColor03a: #048;
$pgColor03b: #026;
$pgColor03c: #159;
$pgColor04a: #027;
$pgColor04b: #005;
$pgColor04c: #049;

/* @extend
--------------------------------------------------*/
/* icon矢印 */
%arwDefault{
  display: inline-block;
  border-style: solid;
  content: '';
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
%arwSizeS{
  width: 5px;
  height: 5px;
}
%arwSizeM{
  width: 6px;
  height: 6px;
}
%arwSizeL{
  width: 8px;
  height: 8px;
}
%arwBorderS{
  border-width: 2px 2px 0 0;
}
%arwBorderM{
  border-width: 3px 3px 0 0;
}
%arwColorB{
  color: #000;
}
%arwColorW{
  color: #fff;
}
