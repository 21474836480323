@charset "utf-8";
/*--------------------------------------------------------------------
  サイト全体
----------------------------------------------------------------------*/

/* default
--------------------------------------------------*/
html,body,div,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,em,img,small,strong,sub,sup,i,dl,dt,dd,ol,ul,li,form,label,table,caption,tbody,tfoot,thead,tr,th,td,article,figcaption,figure,footer,header,nav,section,summary{margin:0;padding:0;border:0;outline:0;font-size:100%;background:transparent;word-wrap:break-word;}
html{overflow-y:scroll;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;}
h1,h2,h3,h4,h5,h6{font-weight:normal;}
article,aside,details,figcaption,figure,footer,header,main,menu,nav,section,summary{display:block;}code,kbd,pre,samp {font-family:monospace,monospace;font-size:1em;}
a{background:transparent;}a:active,a:hover{outline-width:0;}
em{font-style:normal}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}sub{bottom:-.25em}sup{top:-.5em}
ul,ol{list-style-type:none;}dl{-webkit-margin-before:0;-webkit-margin-after:0;}dd{-webkit-margin-start:0;}
table{border-collapse:collapse;border-spacing:0;}td,th{padding:0;}
img{border-style:none;vertical-align:middle;}
hr{overflow: visible;height:0;box-sizing: content-box;}
button,input,select,textarea{margin:0;font:inherit;color:inherit;outline:none;}optgroup{font-weight:700}button,input,select{overflow:visible}button,select{text-transform:none}[type=button],[type=reset],[type=submit],button{padding:0;border:none;background:transparent;cursor:pointer;}[type=button]:focus{outline:none;outline-offset:0}[disabled]{cursor:default}[type=reset],[type=submit],button,html[type="button"]{-webkit-appearance:button}button::-moz-focus-inner,input::-moz-focus-inner{border:0;padding:0}button:-moz-focusring,input:-moz-focusring{outline:1px dotted ButtonText}textarea{overflow:auto;resize:vertical;}[type=checkbox],[type=radio]{box-sizing:border-box;padding:0}[type="number"]::-webkit-inner-spin-button,[type="number"]::-webkit-outer-spin-button{height:auto}[type=search]{-webkit-appearance:textfield}[type="search"]::-webkit-search-cancel-button,[type="search"]::-webkit-search-decoration{-webkit-appearance:none}input:focus,select:focus,textarea:focus{outline:0;border-color:transparent;-webkit-tap-highlight-color:rgba(0,0,0,0);border:none;background:transparent;}input:-webkit-autofill,textarea:-webkit-autofill,select:-webkit-autofill{-webkit-box-shadow: 0 1000px white inset}
fieldset{margin:0;padding:0;border:none;}legend{padding:0;border:none;color:inherit;white-space:normal;}
pre{white-space:pre-wrap;}


/* html
--------------------------------------------------*/
html{
  height: 100%;
  font-family: 'pc';
  font-size: 62.5%;
}

@media #{$sp}{
  html{
    font-family: 'sp';
  }
}

/* body
--------------------------------------------------*/
body{
  position: relative;
  min-width: 1000px;
  height: 100%;
  padding-top: 140px;
  color: #000;
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
  font-size: 1.4rem;
  line-height: 1.45;
}

@media #{$sp}{
  body{
    min-width: 320px;
    padding-top: 60px;
  }
}

/* link
--------------------------------------------------*/
a{
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  text-decoration: none;
  color: #333;
}
.pc a:hover,
a:active{
  outline: none;
}

/* clearfix
--------------------------------------------------*/
.cf:after{
  display: block;
  clear: both;
  content: "";
}

/* ロード時transition無効
--------------------------------------------------*/
.preload *,
.preload *:before,
.preload *:after{
  transition: none !important;
}
