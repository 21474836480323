@charset "utf-8";
/*--------------------------------------------------------------------
   Component
----------------------------------------------------------------------*/

/* _ttl
--------------------------------------------------*/
.c_mainTtl{
  display: table-cell;
  width: 1000px;
  height: 130px;
  margin-bottom: 40px;
  padding: 0 25px;
  background: url(/images/page_bg_ttl01.png) no-repeat 100% 100% $pgColor01a;
  vertical-align: middle;
  font-size: 2.8rem;
  color: #fff;
  box-sizing: border-box;
}
.c_middleTtl{
  margin-bottom: 40px;
  padding: 7px 2em 3px;
  border: 1px solid $pgColor01a;
  border-width: 1px 0;
  box-sizing: border-box;
  font-size: 2rem;
  text-align: center;
  color: $pgColor01a;
  line-height: 1.4;
}
.c_blockTtl{
  font-size: 1.6rem;
  font-weight: bold;
}
.c_blockTtl._square:before{
  display: inline-block;
  content: '■';
}

/* _txt
--------------------------------------------------*/
.c_txt{
  margin: 1em 0;
  line-height: 1.75;
}

/* _ruleTxt
--------------------------------------------------*/
.c_ruleTxt{
  margin-bottom: 1em;
  padding-left: 1em;
  text-indent: -1em;
  font-size: 1.2rem;
}

/* _txtLink
--------------------------------------------------*/
.c_txtLink{
  text-decoration: underline;
}
.pc .c_txtLink:hover{
  text-decoration: none;
}

/* _bnrLink
--------------------------------------------------*/
.pc .c_bnrLink{
  display: inline-block;
  box-shadow: 0 0 0 2px rgba(0, 68, 136, 0);
  transition: all .1s ease-out;
}
.pc .c_bnrLink:hover{
  box-shadow: 0 0 0 2px $pgColor01a;
}

/* _btn
--------------------------------------------------*/
.c_btn{
  position: relative;
  display: block;
  width: 100%;
  padding: 16px 15px 14px;
  box-sizing: border-box;
  color: #fff;
  text-align: left;
  text-decoration: none;
  line-height: 1.5;
  -webkit-appearance: none;
  appearance: none;
  transition: all .1s ease-out;
}
.c_btn._wideUse{
  background: $pgColor01a;
}
.pc .c_btn._wideUse:hover{
  background: #c34;
}
.c_btn._sizeL{
  font-size: 1.7rem;
}
.c_btn._prev{
  padding-left: 23px;
}
.c_btn._next{
  padding-right: 23px;
}
.c_btn._prev:after,
.c_btn._next:after,
.c_btn._download:after{
  position: absolute;
  top: 50%;
  content: '';
}
.c_btn._prev:after,
.c_btn._next:after{
  @extend %arwSizeL;
  margin-top: -4px;
  border-style: solid;
  @extend %arwBorderM;
  @extend %arwColorW;
}
.c_btn._next:after{
  right: 15px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.c_btn._prev:after{
  left: 15px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

/* _Dl
--------------------------------------------------*/
.c_txtDl{
  position: relative;
}
.c_txtDl:after{
  display: inline-block;
  background: url(/images/ico_file.png) no-repeat 0 0;
  background-size: cover;
  content: '';
}
.c_txtDl:after{
  width: 24px;
  height: 24px;
  margin-left: 3px;
  vertical-align: text-bottom;
}

/* _blank
--------------------------------------------------*/
.c_txtBlank,
.c_btnBlank{
  position: relative;
  margin-right: 20px;
}
.c_txtBlank{
  display: inline-block;
}
.c_txtBlank::before,
.c_txtBlank::after,
.c_btnBlank::before,
.c_btnBlank::after{
  display: inline-block;
  position: absolute;
  width: 6px;
  height: 6px;
  content: '';
}
.c_txtBlank{
  &::before,
  &::after{
    top: 50%;
    border: 1px solid #333;
  }
}
.c_btnBlank{
  &::before,
  &::after{
    bottom: 0;
    border: 1px solid #fff;
  }
}
.c_txtBlank::before,
.c_btnBlank::before{
  right: -12px;
}
.c_txtBlank::before{
  margin-top: -2px;
}
.c_btnBlank::before{
  margin-bottom: .4em;
}
.c_txtBlank::after,
.c_btnBlank::after{
  right: -15px;
}
.c_txtBlank::after{
  margin-top: -5px;
}
.c_btnBlank::after{
  margin-bottom: .6em;
}

@media #{$sp}{

  /* _ttl
  --------------------------------------------------*/
  .c_mainTtl{
    min-width: 100%;
    height: 66px;
    margin-bottom: 25px;
    padding: 8px 10px 5px;
    background-size: 83px auto;
    font-size: 2rem;
    line-height: 1.2;
  }
  .c_middleTtl{
    margin-bottom: 20px;
    padding: 8px 5px 5px;
    font-size: 1.7rem;
    text-align: left;
    line-height: 1.2;
  }

    /* _btn
  --------------------------------------------------*/
  .c_btn:after{
    right: 10px;
    border-width: 2px 2px 0 0;
  }
  .c_btn._sizeL{
    font-size: 1.5rem;
  }
  .c_btn._download{
    width: 100%;
    padding-right: 60px;
  }
  .c_btn._download:after{
    right: 10px;
    width: 42px;
    height: 42px;
    margin-top: -21px;
  }

}
